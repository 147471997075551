@use "../../global-styles/colors";

.rack-container {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid colors.$border-color;
  border-bottom: none;
  width: 100%;
  min-width: 700px;
  font-size: 14px;

  .terminal-equipment-table-container {
    min-width: initial;
  }

  .rack-container-header {
    display: flex;
    justify-content: flex-start;
    padding: 15px 10px;
    background-color: colors.$hover-color;
    border-bottom: none;
    border-bottom: 1px solid colors.$border-color;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    align-items: center;
    gap: 20px;

    p {
      flex: 1;
      font-weight: bold;
    }

    .header-icons {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      user-select: none;
      font-size: 22px;
      gap: 10px;

      .header-icons__icon {
        &:hover {
          cursor: pointer;
        }

        &.header-icons__icon--selected {
          color: colors.$success-color;
        }
      }
    }
  }

  .terminal-equipment-table-container {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid colors.$border-color;

    &:last-child {
      border-bottom: none;
    }
  }
}

.terminal-equipment {
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 20px;
}

.terminal-equipment-table-container {
  background-color: white;
  border-radius: 4px;
  border: 1px solid colors.$border-color;
  flex-basis: 100%;
  border-bottom: none;
  min-width: 700px;

  .terminal-equipment-table-container-header {
    display: flex;
    padding: 10px;
    background-color: colors.$hover-color;
    align-items: center;
    gap: 20px;

    p {
      flex: 1;
      font-weight: bold;
    }

    .header-icons {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      user-select: none;
      font-size: 22px;
      gap: 10px;

      .header-icons__icon {
        &:hover {
          cursor: pointer;
        }

        &.header-icons__icon--selected {
          color: colors.$success-color;
        }
      }
    }
  }

  .terminal-equipment-table-container-body {
  }
}

.terminal-equipment-table-grid-header,
.terminal-equipment-table-grid-equipped {
  display: grid;
  grid-template-columns: 4fr 3.5fr 3fr 3.5fr 4fr;
}

.terminal-equipment-table-grid-free {
  display: grid;
  grid-template-columns: 7.5fr 3fr 7.5fr;
}

.terminal-equipment-table {
  border-top: 1px solid colors.$border-color;

  .terminal-equipment-header {
    font-weight: bold;
    border-bottom: 1px solid colors.$border-color;
  }

  .terminal-equipment-table-body {
    &.terminal-equipment-table-body--rack {
    }

    .terminal-equipment-body-rows {
    }

    .terminal-equipment-table-row {
      flex: 1;
      border-bottom: 1px solid colors.$border-color;

      .terminal-equipment-header-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 10px;
        background-color: colors.$ultra-light-grey;

        .terminal-equipment-row-header__item {
          flex: 1;
          font-weight: bold;
        }

        .header-icons {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          user-select: none;
          gap: 10px;
          font-size: 22px;

          .header-icons__icon {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .terminal-equipment-data-row {
      }
    }
  }

  .terminal-equipment-table-item {
    padding: 7px 10px;
    border-right: 1px solid colors.$border-color;
    display: flex;
    align-items: center;

    &:last-child {
      padding: 7px 20px 7px 10px;
    }

    &:nth-of-type(5) {
      border-right: none;
    }

    .table-item-terminal {
      width: 100%;
      display: flex;
      align-items: center;

      .table-item-terminal__item {
        text-align: center;
        flex: 1;

        &:first-child {
          flex: 0.5;
        }

        &:nth-child(3) {
          flex: 0.5;
        }

        &:last-child {
          flex: 0.5;
        }

        svg {
          font-size: 18px;
          user-select: none;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .terminal-equipment-table-item__icon {
      user-select: none;
      padding-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .terminal-equipment-table-item--free {
    background-color: #b3e4b3;
    text-align: center;

    &:last-child {
      border-right: none;
    }
  }
}

.terminal-equipment-trace-view {
  .trace-view-title-header {
    display: flex;
    width: 100%;
    background-color: #f9f9f9;
    border-bottom: 1px solid colors.$border-color;
    padding: 10px;
    align-items: center;
    justify-content: space-between;

    .trace-view-title {
      flex: 1;
      font-weight: bold;
      font-size: 14px;
    }

    .trace-view-actions {
      flex: 1;
      display: flex;
      font-size: 22px;
      flex-direction: row-reverse;
      gap: 10px;
      padding-right: 10px;
      user-select: none;

      .trace-view-actions-action {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .trace-view-grid {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }

  .trace-view-header {
    border-bottom: 1px solid colors.$border-color;

    .trace-view-header-item {
      background-color: #f9f9f9;
      padding: 10px;
      border-right: 1px solid colors.$border-color;
      align-items: center;
      font-weight: bold;
      &:last-child {
        padding: 10px 20px 10px 10px;
      }

      &:nth-of-type(6) {
        border-right: none;
      }
    }
  }

  .trace-view-body {
    .trace-view-body-row {
      border-bottom: 1px solid colors.$border-color;

      &.trace-view-body-row--selected {
        background-color: colors.$select-color;
      }

      &:hover {
        cursor: pointer;
        background-color: colors.$select-color;
      }

      .trace-view-body-item {
        padding: 10px;
        border-right: 1px solid colors.$border-color;
        align-items: center;
        overflow: auto;
        text-overflow: inherit;

        &:last-child {
          padding: 10px 20px 10px 10px;
        }

        &:nth-of-type(6) {
          border-right: none;
        }
      }
    }
  }
}

.color-coded {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .color-coded-item {
    display: flex;
    flex-basis: 100%;

    .color-coded-item-text {
      display: flex;
      gap: 5px;
    }
  }

  .box {
    height: 15px;
    width: 15px;
    border: 1px solid black;
  }

  .rd {
    background-color: #cc0000;
  }

  .gr {
    background-color: #00ff00;
  }

  .bl {
    background-color: #0000ff;
  }

  .yl {
    background-color: #ffff00;
  }

  .wh {
    background-color: #ffffff;
  }

  .sl {
    background-color: #808080;
  }

  .br {
    background-color: #996633;
  }

  .vi {
    background-color: #9900cc;
  }

  .tq {
    background-color: #00ffff;
  }

  .bk {
    background-color: #000000;
  }

  .or {
    background-color: #ff9900;
  }

  .pk {
    background-color: #ff99cc;
  }

  // This is done to support hover on colors on mobile devices.
  @media (pointer: coarse), (hover: none) {
    [title] {
      position: relative;
      display: inline-flex;
      justify-content: center;
    }
    [title]:focus::after {
      content: attr(title);
      position: absolute;
      top: 90%;
      color: #000;
      background-color: #fff;
      border: 1px solid;
      width: fit-content;
      padding: 3px;
    }
  }
}
